export default {
    solicitud:{
        constantes:{
            ESTATUS_SOLICITUD_INACTIVA: 0,
            ESTATUS_SOLICITUD_ACTIVA: 1,
            ESTATUS_SOLICITUD_ACEPTADA_FONDEADOR: 2,
            ESTATUS_SOLICITUD_RECHAZADA_FONDEADOR: 3,
            ESTATUS_SOLICITUD_EN_ESPERA_DE_FORMALIZACION: 4,
            ESTATUS_SOLICITUD_SELECCIONADA_FORMALIZACION: 5,
            ESTATUS_SOLICITUD_RECHAZADA_FORMALIZACION: 6,
            ESTATUS_VOTACION_ABIERTA:0,
            ESTATUS_VOTACION_CERRADA:1,
            ESTATUS_DOCUMENTACION_INCOMPLETA:0,
            ESTATUS_DOCUMENTACION_COMPLETA:1,
            ESTATUS_NOTIFICACION_TIEMPO_LIMITE_NO_ENVIADA:0,
            ESTATUS_NOTIFICACION_TIEMPO_LIMITE_ENVIADA:1,
        },
        estatusValidos(valor, estatus){
            let estatusValores = [];
    
            estatus.forEach(key => {
                if(this.constantes[key])
                    estatusValores.push(this.constantes[key]);
            });

            return estatusValores.indexOf(valor) != -1;
        },
    }

}